import * as React from 'react';

const HeaderLogo = ({
  className,
  ...props
}) => {
  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213.46 72.73"
      className={className} {...props}>
      <g id="Layer_1-2">
        <polygon className="fill-inherit" style={{strokeWidth: '0px'}} points="30.48 1.28 30.48 0 22.24 0 14.24 10.32 14.24 11.04 15.52 11.04 30.48 1.28"/>
        <polygon className="fill-inherit" style={{strokeWidth: '0px'}} points="31.6 66.97 12.48 66.97 14.4 65.53 14.4 44.56 27.04 44.56 30 50.24 30.72 50.24 30.72 34.08 30 34.08 27.04 39.76 14.4 39.76 14.4 20.56 31.52 20.56 37.52 27.52 38.24 27.52 36.96 15.84 0 15.84 0 17.12 6.4 19.84 6.4 67.77 0 70.25 0 71.85 37.76 71.85 39.12 57.36 38.4 57.36 31.6 66.97"/>
        <polygon className="fill-inherit" style={{strokeWidth: '0px'}} points="132.71 17.44 138.71 19.84 138.71 39.76 110.3 39.76 110.3 19.84 116.31 17.44 116.31 15.84 95.9 15.84 95.9 17.44 102.3 19.84 102.3 67.77 95.9 70.25 95.9 71.85 116.31 71.85 116.31 70.25 108.86 67.77 110.3 66.81 110.3 44.56 138.71 44.56 138.71 67.77 132.71 70.25 132.71 71.85 153.11 71.85 153.11 70.25 145.27 67.77 146.71 66.81 146.71 19.84 153.11 17.44 153.11 15.84 132.71 15.84 132.71 17.44"/>
        <path className="fill-inherit" style={{strokeWidth: '0px'}} d="M86.56,64.01c-2.03,1.33-4.51,2.32-7.44,2.96-1.44.27-2.72.4-3.84.4-4.64,0-8.81-1.61-12.52-4.84-3.71-3.23-6.31-7.8-7.8-13.72-.8-2.99-1.2-5.89-1.2-8.72,0-4.64,1.08-8.68,3.24-12.12,2.16-3.44,5.4-5.83,9.72-7.16,2.08-.64,4.13-.96,6.16-.96,4.27,0,8.45,1.52,12.56,4.56l4,3.12h.72l-1.28-11.68c-1.17,0-2.43-.05-3.76-.16-1.33-.11-2.27-.19-2.8-.24-3.31-.32-6.11-.48-8.4-.48-5.28,0-10.13,1.29-14.56,3.88-4.43,2.59-7.92,6.09-10.48,10.52-2.56,4.43-3.84,9.25-3.84,14.48,0,6.03,1.25,11.21,3.76,15.56,2.51,4.35,5.87,7.65,10.08,9.92,4.21,2.27,8.85,3.4,13.92,3.4,8.05,0,14.48-2.75,19.28-8.24l.88-5.52h-.72c-1.76,2.03-3.65,3.71-5.68,5.04Z"/>
        <path className="fill-inherit" style={{strokeWidth: '0px'}} d="M209.58,29.36c-2.59-4.43-6.11-7.93-10.56-10.52-4.45-2.59-9.29-3.88-14.52-3.88s-10.16,1.29-14.64,3.88c-4.48,2.59-8.03,6.09-10.64,10.52-2.61,4.43-3.92,9.25-3.92,14.48s1.31,10.05,3.92,14.48c2.61,4.43,6.16,7.93,10.64,10.52,4.48,2.59,9.36,3.88,14.64,3.88s10.13-1.29,14.56-3.88c4.43-2.59,7.93-6.09,10.52-10.52,2.59-4.43,3.88-9.25,3.88-14.48s-1.29-10.05-3.88-14.48ZM201.1,60.12c-2.27,3.49-5.72,5.83-10.36,7-1.76.48-3.47.72-5.12.72-4.91,0-9.13-1.8-12.68-5.4-3.55-3.6-6.04-8.17-7.48-13.72-.8-2.99-1.2-5.95-1.2-8.88,0-4.69,1.13-8.77,3.4-12.24,2.27-3.47,5.75-5.84,10.44-7.12,1.55-.43,3.23-.64,5.04-.64,4.85,0,9.04,1.77,12.56,5.32,3.52,3.55,6.03,8.09,7.52,13.64.85,3.15,1.28,6.13,1.28,8.96,0,4.75-1.13,8.87-3.4,12.36Z"/>
      </g>
    </svg>
  )
}

export default HeaderLogo;