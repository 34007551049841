import * as React from 'react';

const HeaderShortLogo = ({
  className,
  ...props
}) => {
  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.12 71.85"
      className={className} {...props}>
      <g id="Layer_1-2">
        <polygon className="fill-inherit" style={{strokeWidth: '0px'}} points="30.48 1.28 30.48 0 22.24 0 14.24 10.32 14.24 11.04 15.52 11.04 30.48 1.28"/>
        <polygon className="fill-inherit" style={{strokeWidth: '0px'}} points="31.6 66.97 12.48 66.97 14.4 65.53 14.4 44.56 27.04 44.56 30 50.24 30.72 50.24 30.72 34.08 30 34.08 27.04 39.76 14.4 39.76 14.4 20.56 31.52 20.56 37.52 27.52 38.24 27.52 36.96 15.84 0 15.84 0 17.12 6.4 19.84 6.4 67.77 0 70.25 0 71.85 37.76 71.85 39.12 57.36 38.4 57.36 31.6 66.97"/>
      </g>
    </svg>
  )
}

export default HeaderShortLogo;