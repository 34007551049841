import { Disclosure, Transition } from '@headlessui/react';
import * as React from 'react';
import MenuIconActive from './menuIconActive';
import MenuIcon from './menuIcon';
import { graphql, useStaticQuery } from 'gatsby';
import clsx from 'clsx';
import HeaderLogo from './headerLogo';
import HeaderShortLogo from './headerShortLogo';
import Link from './link';

const menuIconStyle = "w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 fill-[color:var(--color-scheme-icon)] stroke-[color:var(--color-scheme-icon)]";

const Navbar = ({
  pathname,
  navBackgroundClass = "color-scheme-two"
}) => {
  const isHomepage = !pathname.length;
  const headerSpotRef = React.useRef(null);
  const headerRef = React.useRef(null);
  const [isFixed, setIsFixed] = React.useState(false);

  const navSettings = useStaticQuery(graphql`
    query {
      sanityNavigation {
        links {
          url
          noFollow
          newWindow
          label
        }
      }
    }
  `)

  const {
    links
  } = navSettings.sanityNavigation

  React.useEffect(() => {
    const target = headerSpotRef.current;
    const observer = new IntersectionObserver((entries) => {
      const isVisible = entries[0].isIntersecting;
      if(isVisible) {
        setIsFixed(false);
        target.style.removeProperty('--custom-height');
      }
      else {
        setIsFixed(true);
        const customHeight = headerRef.current?.offsetHeight;
        target.style.setProperty('--custom-height', `${customHeight}px`);
      }
    }, {
      root: null, 
      rootMargin: "0px", 
      threshhold: 0, 
    })

    if(target) {
      observer.observe(target);
    }

    return () => {
      if(target) {
        observer.unobserve(target)
      }
    };
  }, []);
  return (
    <>
      <Disclosure as="nav" className={clsx("bg-[var(--color-scheme-background)] text-[color:var(--color-scheme-foreground)] z-10",  (isFixed || isHomepage) ? `color-scheme-two fixed w-full transition-all duration-300` : navBackgroundClass, (!isHomepage || isFixed) && 'top-0')}
        style={isHomepage && !isFixed ? {'--color-scheme-background': 'transparent'} : {}}>
        {({ open }) => (
          <>
            <div className={clsx("relative", (!isHomepage || isFixed) ? 'contain' : 'px-2 lg:px-2 xl:px-12')} ref={headerRef}>
              <div className="relative flex h-28 justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center">
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className={menuIconStyle} />
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center">
                  <Link to="/" className="flex flex-shrink-0 items-center h-12 md:h-16">
                    {isFixed ? <HeaderShortLogo className='fill-[var(--color-scheme-foreground)] w-6 md:w-8' />
                      : <HeaderLogo className='fill-[var(--color-scheme-foreground)] w-36 md:w-48' />}
                  </Link>
                </div>
                <div className="hidden absolute inset-y-0 right-0 md:flex items-center">
                  <div className="flex space-x-8 text-xl pr-2">
                    <Link to='/contact-us'
                      className='inline-flex items-center border-y-2 border-[color:var(--color-scheme-foreground)] px-1 font-heading pt-1'>
                        CONTACT US
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <Transition enter="duration-700 transition fixed h-full w-full left-0 top-0 translate-x-0 z-10"
              enterFrom="!-translate-x-full"
              enterTo="translate-x-0"
              leave="duration-500 transition fixed left-0 top-0 h-full w-full origin-bottom-right opacity-0 z-10"
              leaveFrom="scale-100 origin-bottom-right opacity-100"
              leaveTo="scale-0 origin-top-left opacity-0">
              <Disclosure.Panel className={clsx('fixed overflow-auto left-0 top-0 h-full w-full bg-[var(--color-scheme-background)] translate-x-0 translate-y-0 z-10', navBackgroundClass)}>
                <div className="contain relative">
                  <div className="relative flex h-28 justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center">
                      <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2">
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Close main menu</span>
                        <MenuIconActive  className={menuIconStyle} />
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>
                  <div className="contain w-full py-24">
                    <h5 className='font-body text-2xl text-center mb-8 text-[color:var(--color-scheme-foreground)]'>MENU</h5>
                    <div className='flex flex-col items-center space-y-16 text-xl'>
                      {links.map((i,k) => (
                        <Link key={k}
                          to={i.url}
                          {...i}
                          className="font-heading text-4xl sm:text-6xl text-center group">
                            <div className="text-[color:var(--color-scheme-foreground)]">{i.label}</div>
                            <div className='bg-[var(--color-scheme-background)] group-hover:bg-[var(--color-scheme-border)] w-full h-[2px] transition-all duration-300'></div>
                          </Link>
                      ))}
                    </div>
                  </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      
      <div className='h-[var(--custom-height)]' ref={headerSpotRef}></div>
    </>
  )
}

export default Navbar;