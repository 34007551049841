import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';

const Link = ({ children, to, activeClassName, partiallyActive, newWindow, noFollow, ...other }) => {
  const internal = /^\/(?!\/)/.test(to);

  if(internal) {
    return (
      <GatsbyLink to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}>
        {children}
      </GatsbyLink>
    )
  }
  const props = other;
  if(newWindow) {
    props.target = "_blank";
  }
  if(noFollow) {
    props.rel = 'nofollow';
  }
  return (
    
    <a href={to} {...other}>
      {children}
    </a>
  )
}

export default Link;