import * as React from 'react';
import Link from './link';
import clsx from 'clsx';

const ScrollButton = ({link, label, className, labelClassName, ...props}) => {
  const textRef = React.useRef(null);
  const [buttonWidth, setButtonWidth] = React.useState(0);

  React.useEffect(() => {
    setButtonWidth(textRef?.current?.offsetWidth);
  }, [textRef])

  const content = <div className='group-hover:animate-infinite-scroll group-disabled:animate-none flex flex-row flex-nowrap gap-x-4'
    style={{
      width: `${buttonWidth}px`
    }}>
      <div ref={textRef} className={clsx('whitespace-nowrap', labelClassName)}>{label}</div>
      <span className={clsx('whitespace-nowrap', labelClassName)}>{label}</span>
      <span className={clsx('whitespace-nowrap', labelClassName)}>{label}</span>
  </div>;

  if (link) {
    <Link className={clsx(className, 'overflow-hidden group')}
      {...props}>
      {content}
    </Link>
  }

  return (
    <button className={clsx(className, 'overflow-hidden group')}
      {...props}>
      {content}
    </button>
  )
}

export default ScrollButton;