import * as React from 'react';

const FooterLogo = ({
  className,
  ...props
}) => {
  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 474.44 161.64"
    className={className} {...props}>
      <g id="Layer_1-2">
        <polygon className="fill-inherit" style={{strokeWidth: '0px'}} points="67.75 2.84 67.75 0 49.43 0 31.65 22.94 31.65 24.54 34.5 24.54 67.75 2.84"/>
        <polygon className="fill-inherit" style={{strokeWidth: '0px'}} points="70.24 148.84 27.74 148.84 32.01 145.64 32.01 99.05 60.1 99.05 66.68 111.67 68.28 111.67 68.28 75.75 66.68 75.75 60.1 88.38 32.01 88.38 32.01 45.7 70.06 45.7 83.4 61.17 85 61.17 82.15 35.21 0 35.21 0 38.05 14.23 44.1 14.23 150.61 0 156.13 0 159.68 83.93 159.68 86.96 127.5 85.35 127.5 70.24 148.84"/>
        <polygon className="fill-inherit" style={{strokeWidth: '0px'}} points="294.95 38.77 308.29 44.1 308.29 88.38 245.16 88.38 245.16 44.1 258.5 38.77 258.5 35.21 213.15 35.21 213.15 38.77 227.38 44.1 227.38 150.61 213.15 156.13 213.15 159.68 258.5 159.68 258.5 156.13 241.96 150.61 245.16 148.48 245.16 99.05 308.29 99.05 308.29 150.61 294.95 156.13 294.95 159.68 340.3 159.68 340.3 156.13 322.87 150.61 326.07 148.48 326.07 44.1 340.3 38.77 340.3 35.21 294.95 35.21 294.95 38.77"/>
        <path className="fill-inherit" style={{strokeWidth: '0px'}} d="M192.39,142.26c-4.51,2.96-10.02,5.16-16.54,6.58-3.2.59-6.05.89-8.53.89-10.31,0-19.59-3.58-27.83-10.76-8.24-7.17-14.02-17.34-17.34-30.5-1.78-6.64-2.67-13.1-2.67-19.38,0-10.31,2.4-19.29,7.2-26.94,4.8-7.65,12-12.95,21.61-15.92,4.62-1.42,9.18-2.13,13.69-2.13,9.48,0,18.79,3.38,27.92,10.14l8.89,6.93h1.6l-2.84-25.96c-2.61,0-5.4-.12-8.36-.36-2.96-.24-5.04-.41-6.22-.53-7.35-.71-13.57-1.07-18.67-1.07-11.74,0-22.52,2.88-32.36,8.62-9.84,5.75-17.6,13.55-23.29,23.38-5.69,9.84-8.53,20.57-8.53,32.19,0,13.4,2.78,24.93,8.36,34.59,5.57,9.66,13.04,17.01,22.41,22.05,9.36,5.04,19.68,7.56,30.94,7.56,17.9,0,32.19-6.1,42.85-18.32l1.96-12.27h-1.6c-3.91,4.51-8.12,8.24-12.63,11.2Z"/>
        <path className="fill-inherit" style={{strokeWidth: '0px'}} d="M465.82,65.26c-5.75-9.84-13.58-17.63-23.47-23.38-9.9-5.75-20.66-8.62-32.27-8.62s-22.58,2.88-32.54,8.62c-9.96,5.75-17.84,13.55-23.65,23.38-5.81,9.84-8.71,20.57-8.71,32.19s2.9,22.35,8.71,32.19c5.81,9.84,13.69,17.64,23.65,23.38,9.96,5.75,20.8,8.62,32.54,8.62s22.52-2.87,32.36-8.62c9.84-5.75,17.63-13.54,23.38-23.38,5.75-9.84,8.62-20.57,8.62-32.19s-2.88-22.34-8.62-32.19ZM446.97,133.63c-5.04,7.77-12.71,12.95-23.03,15.56-3.91,1.07-7.71,1.6-11.38,1.6-10.91,0-20.3-4-28.18-12-7.89-8-13.43-18.17-16.63-30.5-1.78-6.64-2.67-13.22-2.67-19.74,0-10.43,2.52-19.5,7.56-27.21,5.04-7.71,12.77-12.98,23.21-15.83,3.44-.95,7.17-1.42,11.2-1.42,10.79,0,20.09,3.94,27.92,11.82,7.82,7.89,13.4,17.99,16.71,30.32,1.9,7,2.85,13.63,2.85,19.92,0,10.55-2.52,19.71-7.56,27.47Z"/>
        <polygon className="fill-inherit" style={{strokeWidth: '0px'}} points="417.56 88.74 419.19 89.39 419.19 94.78 411.5 94.78 411.5 89.39 413.12 88.74 413.12 88.3 407.6 88.3 407.6 88.74 409.33 89.39 409.33 102.36 407.6 103.03 407.6 103.47 413.12 103.47 413.12 103.03 411.11 102.36 411.5 102.1 411.5 96.08 419.19 96.08 419.19 102.36 417.56 103.03 417.56 103.47 423.09 103.47 423.09 103.03 420.96 102.36 421.35 102.1 421.35 89.39 423.09 88.74 423.09 88.3 417.56 88.3 417.56 88.74"/>
        <polygon className="fill-inherit" style={{strokeWidth: '0px'}} points="390.19 102.15 385.01 102.15 385.53 101.76 385.53 96.08 388.95 96.08 389.75 97.62 389.95 97.62 389.95 93.24 389.75 93.24 388.95 94.78 385.53 94.78 385.53 89.58 390.17 89.58 391.79 91.47 391.99 91.47 391.64 88.3 381.63 88.3 381.63 88.65 383.36 89.39 383.36 102.36 381.63 103.03 381.63 103.47 391.86 103.47 392.22 99.55 392.03 99.55 390.19 102.15"/>
        <path className="fill-inherit" style={{strokeWidth: '0px'}} d="M435.52,89.12c-1.21-.7-2.52-1.05-3.93-1.05s-2.75.35-3.96,1.05c-1.21.7-2.17,1.65-2.88,2.85-.71,1.2-1.06,2.51-1.06,3.92s.35,2.72,1.06,3.92c.71,1.2,1.67,2.15,2.88,2.85,1.21.7,2.53,1.05,3.96,1.05s2.74-.35,3.94-1.05c1.2-.7,2.15-1.65,2.85-2.85.7-1.2,1.05-2.51,1.05-3.92s-.35-2.72-1.05-3.92c-.7-1.2-1.65-2.15-2.86-2.85ZM436.08,100.3c-.61.95-1.55,1.58-2.8,1.9-.48.13-.94.19-1.39.19-1.33,0-2.47-.49-3.43-1.46-.96-.97-1.64-2.21-2.03-3.71-.22-.81-.32-1.61-.32-2.4,0-1.27.31-2.38.92-3.31.61-.94,1.56-1.58,2.83-1.93.42-.12.87-.17,1.36-.17,1.31,0,2.45.48,3.4,1.44.95.96,1.63,2.19,2.04,3.69.23.85.35,1.66.35,2.43,0,1.29-.31,2.4-.92,3.35Z"/>
        <polygon className="fill-inherit" style={{strokeWidth: '0px'}} points="389.89 84.36 389.89 84.02 387.65 84.02 385.49 86.81 385.49 87 385.83 87 389.89 84.36"/>
        <path className="fill-inherit" style={{strokeWidth: '0px'}} d="M405.07,101.35c-.55.36-1.22.63-2.01.8-.39.07-.74.11-1.04.11-1.26,0-2.39-.44-3.39-1.31s-1.71-2.11-2.11-3.72c-.22-.81-.32-1.6-.32-2.36,0-1.26.29-2.35.88-3.28.58-.93,1.46-1.58,2.63-1.94.56-.17,1.12-.26,1.67-.26,1.16,0,2.29.41,3.4,1.24l1.08.84h.2l-.35-3.16c-.32,0-.66-.01-1.02-.04-.36-.03-.61-.05-.76-.06-.9-.09-1.65-.13-2.27-.13-1.43,0-2.74.35-3.94,1.05-1.2.7-2.14,1.65-2.84,2.85-.69,1.2-1.04,2.51-1.04,3.92,0,1.63.34,3.04,1.02,4.21.68,1.18,1.59,2.07,2.73,2.69,1.14.61,2.4.92,3.77.92,2.18,0,3.92-.74,5.22-2.23l.24-1.49h-.2c-.48.55-.99,1-1.54,1.36Z"/>
      </g>
    </svg>
  )
}

export default FooterLogo;