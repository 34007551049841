import * as React from 'react';
import ScrollButton from './scrollButton';
import clsx from 'clsx';

export const FormInput = ({
  type,
  name,
  id,
  placeholder,
  value,
  ...props
}) => {
  return (
    <input type={type}
      name={name}
      id={id}
      placeholder={placeholder}
      value={value}
      className="w-full min-w-0 appearance-none rounded-lg border-0 bg-[var(--color-scheme-input-background)] focus:bg-[var(--color-scheme-input-background-active)] px-4 py-3.5  text-[color:var(--color-scheme-input-foreground)] focus:text-[color:var(--color-scheme-input-foreground-active)] shadow-sm ring-1 ring-inset ring-[color:var(--color-scheme-input-border)] placeholder:text-[color:var(--color-scheme-input-placeholder)] focus:ring-2 focus:ring-inset focus:ring-[color:var(--color-scheme-input-border-active)]"
      {...props} />
  )
}

export const FormText = ({
  name,
  id,
  rows = "12",
  placeholder,
  value,
  ...props
}) => {
  return <textarea name={name}
    id={id}
    rows={rows}
    value={value}
    className="w-full min-w-0 appearance-none rounded-lg border-0 bg-[var(--color-scheme-input-background)] focus:bg-[var(--color-scheme-input-background-active)] px-4 py-3.5  text-[color:var(--color-scheme-input-foreground)] focus:text-[color:var(--color-scheme-input-foreground-active)] shadow-sm ring-1 ring-inset ring-[color:var(--color-scheme-input-border)] placeholder:text-[color:var(--color-scheme-input-placeholder)] focus:ring-2 focus:ring-inset focus:ring-[color:var(--color-scheme-input-border-active)]"
    placeholder={placeholder}
    {...props}></textarea>
}

export const FormField = ({
  fieldType,
  ...props
}) => {
  switch (fieldType) {
    case 'input':
      return <FormInput {...props} />
    case 'text':
      return <FormText {...props} />
    default:
      return <></>
  }
}

const FormBlock = ({
  fields = [],
  gridColumns = 1,
  id,
  terms = {},
  submitButton = {}
}) => {
  const getFieldColumnSpan = ({
    threeColumnWidth,
    twoColumnWidth
  }) => {
    switch (parseInt(gridColumns)) {
      case 1:
        return 'md:col-span-1';
      case 2:
        return parseInt(twoColumnWidth) === 2 ? 'md:col-span-2' : 'md:col-span-1';
      case 3:
        return parseInt(threeColumnWidth) === 3 ? 'md:col-span-3' : (parseInt(threeColumnWidth) === 2 ? 'md:col-span-2' : 'md:col-span-1');
      default:
        return '';
    }
  }
  const buttonColorClasses = submitButton.color === 'buttonTwo' ? 'bg-[var(--color-scheme-button-two-background)] text-[color:var(--color-scheme-button-two-foreground)] border-[color:var(--color-scheme-button-two-border)] hover:bg-[var(--color-scheme-button-two-background-active)] hover:text-[color:var(--color-scheme-button-two-foreground-active)] hover:border-[color:var(--color-scheme-button-two-border-active)]' : 'bg-[var(--color-scheme-button-one-background)] text-[color:var(--color-scheme-button-one-foreground)] border-[color:var(--color-scheme-button-one-border)] hover:bg-[var(--color-scheme-button-one-background-active)] hover:text-[color:var(--color-scheme-button-one-foreground-active)] hover:border-[color:var(--color-scheme-button-one-border-active)]';
  return (
    <div className='w-full grid grid-cols-1 md:grid-cols-[var(--section-grid-columns)] gap-x-8 gap-y-8'
      style={{
        '--section-grid-columns': `repeat(${gridColumns}, minmax(0, 1fr))`
      }}>
      {fields.map((i,k) => (
        <div key={k}
          className={getFieldColumnSpan(i)}>
          <FormField fieldType={i.fieldType}
            name={i.id.current}
            id={`${id.current}-${i.id.current}`}
            placeholder={i.placeholder}
            type={i.inputType} />
        </div>
      ))}
      <div style={{
        '--col-span': `span ${gridColumns}/span ${gridColumns}`
        }}
        className={clsx('md:col-[var(--col-span)] flex', submitButton.position === 'inline' ? 'items-center gap-x-4' : 'gap-y-4 flex-col')}>
        {terms?.text &&
          <p className='flex-1 text-[color:var(--color-scheme-foreground)]'>{terms.text}</p>
        }
        <div>
          <ScrollButton label={submitButton.label}
            className={clsx('border-2 px-4 pt-2 pb-1 rounded-lg color-inherit', buttonColorClasses)}
            labelClassName='font-heading italic text-lg' />
        </div>
      </div>
    </div>
  )
}

export default FormBlock;