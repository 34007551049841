import * as React from 'react';

const EIcon = ({className, ...props}) => {
  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.58 93.26"
      className={className} {...props}>
      <g id="Layer_1-2">
        <polygon className="fill-inherit" style={{strokeWidth: '0px'}} points="43.96 15.58 43.96 14.59 37.61 14.59 31.45 22.54 31.45 23.1 32.43 23.1 43.96 15.58"/>
        <polygon className="fill-inherit" style={{strokeWidth: '0px'}} points="50.06 58.79 44.82 66.19 30.09 66.19 31.57 65.08 31.57 48.93 41.31 48.93 43.59 53.31 44.15 53.31 44.15 40.85 43.59 40.85 41.31 45.23 31.57 45.23 31.57 30.44 44.76 30.44 49.39 35.8 49.94 35.8 48.95 26.8 20.47 26.8 20.47 27.78 25.4 29.88 25.4 66.81 20.47 68.72 20.47 69.95 49.57 69.95 50.62 58.79 50.06 58.79"/>
        <path className="fill-inherit" style={{strokeWidth: '0px'}} d="M67.12,20.55C62.21,8.37,52.7.99,40.87.35c-1.64-.23-3.33-.35-5.08-.35-1.85,0-3.63.13-5.36.39-.83.05-1.65.13-2.46.25C16.72,2.17,8.16,10.48,4.08,21.55c-5.54,14.65-5.46,36.64.38,51.16,4.91,12.18,14.43,19.56,26.26,20.2,1.64.23,3.33.35,5.08.35,1.85,0,3.63-.13,5.36-.39.83-.05,1.65-.13,2.46-.25,11.25-1.53,19.8-9.84,23.89-20.91,5.54-14.65,5.46-36.64-.38-51.16ZM68.5,46.63c0,15.48-3.24,27.29-9.62,35.09-5.55,6.78-13.53,10.37-23.09,10.37s-17.54-3.58-23.09-10.37c-6.38-7.8-9.62-19.61-9.62-35.09S6.31,19.34,12.7,11.54C18.25,4.76,26.23,1.18,35.79,1.18s17.54,3.58,23.09,10.37c6.38,7.8,9.62,19.61,9.62,35.09Z"/>
      </g>
    </svg>
  )
}

export default EIcon