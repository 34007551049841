import * as React from 'react';

const MenuIcon = ({
  className
}) => {
  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.8 67.49"
     className={className}>
      <g id="Layer_1-2">
        <path className="fill-inherit" style={{
          strokeWidth: '0px'
        }} d="M48.58,14.87C45.02,6.06,38.14.72,29.58.25c-1.19-.17-2.41-.25-3.68-.25-1.34,0-2.63.09-3.88.28-.6.04-1.2.1-1.78.18C12.1,1.57,5.91,7.59,2.95,15.6c-4.01,10.6-3.95,26.52.27,37.03,3.55,8.81,10.44,14.15,19,14.62,1.19.17,2.41.25,3.68.25,1.34,0,2.63-.09,3.88-.28.6-.04,1.2-.1,1.78-.18,8.14-1.11,14.33-7.12,17.29-15.13,4.01-10.6,3.95-26.52-.27-37.03ZM49.57,33.75c0,11.21-2.34,19.75-6.96,25.39-4.02,4.91-9.79,7.5-16.71,7.5s-12.7-2.59-16.71-7.5c-4.62-5.65-6.96-14.19-6.96-25.39s2.34-19.75,6.96-25.39C13.21,3.44,18.98.85,25.9.85s12.7,2.59,16.71,7.5c4.62,5.65,6.96,14.19,6.96,25.39Z"/>
        <line className="fill-none stroke-inherit" x1="14.64" y1="23.47" x2="37.16" y2="23.47" style={{
          strokeMiterlimit: '10',
          strokeWidth: '2.83px'
        }}/>
        <line className="fill-none stroke-inherit" x1="14.64" y1="33.75" x2="37.16" y2="33.75" style={{
          strokeMiterlimit: '10',
          strokeWidth: '2.83px'
        }}/>
        <line className="fill-none stroke-inherit" x1="14.64" y1="44.02" x2="37.16" y2="44.02" style={{
          strokeMiterlimit: '10',
          strokeWidth: '2.83px'
        }}/>
      </g>
    </svg>
  )
}

export default MenuIcon;